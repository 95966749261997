.OtpFields {
    margin-bottom: 10px;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    
}
.OtpFields input{
    border:#D2D3D8 1px solid;
    width: 52px !important;
    height: 52px !important;
    border-radius: 8px;
    margin-right: 4px !important;
    font-size: 22px;
}

.OtpFields input:nth-child(4) { 
    margin-left:15px;
    margin-right: 15px;
}

.ContinueOtpButton{
    margin-top: 15px;
}


.ResendOtpButton {
    justify-content: center !important;
    display:flex;
}

.ResendOtpButton .MuiButtonBase-root {
    background: #fff !important;
    box-shadow: none !important;
    height: 48px !important;
    border-radius: 8px !important;
    font-weight: 600;
    text-transform: initial !important;
    font-size: 14px;
    padding: 0 20px;
    outline: none !important;
    border: none;
    color: #004FE0 !important;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 10px !important;
    height:  auto !important;
    margin-top: 16px !important;
    text-decoration: underline;
}

