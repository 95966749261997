.SideBarContainer {
  position: fixed;
  background: #fff;
  height: 100%;
  border-right: #c6c6d0 1px solid;
  z-index: 999;
}

.SideBarInnerContainer {
  position: fixed;
  height: 100%;
  background: #fff;
  border-right: #c6c6d0 1px solid;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.SideBarMenu {
  flex: 1 1;
}

.SideBarMenu .ps-submenu-expand-icon.ps-open span {
  width: 8px !important;
  height: 8px !important;
}

.SideBarMenu .ps-submenu-expand-icon span {
  width: 8px !important;
  height: 8px !important;
}

.SideBarMenu .ps-sidebar-container {
  background: transparent !important;
  overflow: visible !important;
}

.SideBarMenu ul li a .ps-menu-label {
  display: flex !important;
  align-items: center !important;
  text-decoration: none;
  color: #0b132b;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.SideBarMenu .ps-submenu-content ul li a span {
  font-size: 14px !important;
}

.SideBarMenu ul li a .ps-menu-label .MuiSvgIcon-root {
  margin-right: 8px;
}

.SideBarMenu ul li a .ps-menu-label svg {
  margin-right: 8px;
  height: 20px;
  width: 20px;
}

.SideBarMenu ul li.LinkActive a .ps-menu-label svg {
  color: #fff !important;
}

.SideBarMenu .ps-menu-label div {
  display: flex !important;
  align-items: center !important;
}

.SideBarMenu .ps-open {
  background: #f8f8f9;
  border-radius: 8px;
}

.SideBarMenu .ps-menu-button {
  border-radius: 8px !important;
}

.SideBarMenu .DotIcon {
  font-size: 12px;
  color: #676c7b;
}

.SideBarMenu .divider {
  height: 1px;
  display: inline-block;
  border-bottom: 1px solid rgb(238, 237, 237);
}

.ps-menu-root {
  padding: 0 16px;
}

.ps-sidebar-root.ps-collapsed .MenuPer {
  display: none;
}

.ps-sidebar-root.ps-collapsed .SideBarContainer {
}

.ps-sidebar-root {
  width: 270px !important;
  border-color: transparent !important;
}

.ps-sidebar-root .ps-menu-button:hover {
  background-color: #f8f8f9 !important;
}

.ps-sidebar-root .ps-menu-button:hover {
  background-color: #f8f8f9 !important;
}

.ps-submenu-content .ps-menu-button {
  margin: 0 15px;
}

.ps-sidebar-root .LinkActive .ps-menu-button {
  background-color: #0450e1 !important;
}

.ps-sidebar-root .LinkActive .ps-menu-button .ps-menu-label {
  color: #ffffff;
}

.ps-menuitem-root {
  margin-bottom: 5px;
}

.ps-sidebar-root.ps-collapsed {
  width: 96px !important;
  display: flex;
  justify-content: center;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-expand-icon {
  display: none;
}

.ps-sidebar-root.ps-collapsed .ps-menuitem-root {
  display: flex;
  justify-content: center;
}

.ps-sidebar-root.ps-collapsed .ps-menu-button {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 44px;
  height: 44px;
  padding: 0 !important;
}

.ps-sidebar-root.ps-collapsed .MuiSvgIcon-root {
  margin-right: 0px !important;
}

.ps-sidebar-root.ps-collapsed ul li a .ps-menu-label {
  display: initial !important;
  font-size: 16px !important;
  color: #0b132b !important;
  font-weight: 500 !important;
}

.ps-sidebar-root.ps-collapsed .ps-menu-label .Submenue {
  display: initial !important;
}

.ps-sidebar-root.ps-collapsed
  .MenuButtonPr.ps-submenu-root:hover
  .ps-submenu-content {
  display: block !important;
  overflow: initial !important;
  visibility: visible !important;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content {
  margin-left: -4px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  width: auto !important;
  position: absolute !important;
  top: 0 !important;
  transform: initial !important;
  left: 50px !important;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content ul {
  background: #fff;
  border-radius: 8px;
  margin-left: 40px;
  position: relative;
  border: 1px solid #efefef;
  min-width: 128px;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content ul:after,
.ps-sidebar-root.ps-collapsed .ps-submenu-content ul:before {
  right: 100%;
  top: 24px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content ul:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content ul:before {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #f3f3f3;
  border-width: 13px;
  margin-top: -13px;
}

.submenutitle {
  display: none;
  padding: 15px;
}

.ps-sidebar-root.ps-collapsed .submenutitle {
  display: block;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
  border-bottom: #e4e8ec 1px solid;
  font-family: "Poppins", sans-serif;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content ul li {
  display: block;
  text-align: left;
  width: auto;
  padding: 5px;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-content ul li .ps-menu-button {
  display: flex;
  text-align: left;
  width: auto;
  padding: 0 15px !important;
}

.ps-sidebar-root.ps-collapsed
  .ps-submenu-content
  ul
  li
  .ps-menu-button
  .MuiSvgIcon-root {
  display: none;
}

.ps-sidebar-root.ps-collapsed .MenueDropdownWithImgCont {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ps-sidebar-root.ps-collapsed .MenueDropdownWithImg {
  height: 64px;
  width: 80px;
}

.ps-sidebar-root.ps-collapsed .SlImLstlabel {
  display: none;
}

.ps-sidebar-root .ps-menu-button .MenuTooltip {
  display: none !important;
}

.ps-sidebar-root.ps-collapsed .ps-menu-button .MenuTooltip {
  margin-left: 40px;
  position: absolute;
  left: 0px;
  top: 0;
}

.ps-sidebar-root.ps-collapsed .ps-menu-button:hover .MenuTooltip {
  display: block !important;
}

.ps-sidebar-root.ps-collapsed .ps-menu-button .MenuTooltip .MenuTooltipLink {
  background: #fff;
  border-radius: 8px;
  margin-left: 45px;
  border: 1px solid #efefef;
  min-width: 128px;
  padding: 15px;
  display: flex;
  align-items: center;
  height: 44px;
  text-decoration: none;
  color: #0b132b;
  font-size: 16px;
  font-weight: 500;
}

.MenuTooltipLink:after,
.MenuTooltipLink:before {
  right: 127px;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.MenuTooltipLink:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}

.MenuTooltipLink:before {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #f3f3f3;
  border-width: 13px;
  margin-top: -13px;
}

.BrandMainLogo {
  padding: 12px 20px 20px 0px;
  max-width: 267px;
}

/* .BrandMainLogo img {
    width: 100%;
} */

.ps-submenu-content .ps-menu-button {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

/*  * ------------------ sidebar menu --------------------- * */
.poweredByContainer {
  margin-left: 16px;
  margin-bottom: 12px;
}

.poweredByContainer p {
  margin: 0;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
}

.poweredByContainer img {
  height: 32px;
  width: 116px;
}
