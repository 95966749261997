.TableSearch {
    display: flex;
    align-items: center;
}

.TableSearch .TableSearchField {
    border: none !important;
    outline: none !important;
}

.TableSearch .TableSearchButton {
    min-width: auto !important;
    padding: 0 !important;

    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #5C677D !important;
    text-transform: initial !important;
    display: flex;

}

.TableSearch .TableSearchButton svg {
    font-size: 18px;
    color: #5C677D !important;
}

.TableSearch .TableSearchField {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #5C677D !important;
}

.SearchFieldDefault .TableSearchField {
    width: 0px;
    opacity: 0;
    animation: mymoveDefault 0.5s;
}

@keyframes mymoveDefault {
    from {
        width: 150px;
    }

    to {
        width: 0px;
    }
}

.SearchFieldActive .TableSearchField {
    width: 150px;
    opacity: 1;
    animation: mymoveActive 0.5s;
}

@keyframes mymoveActive {
    from {
        width: 0px;
    }

    to {
        width: 150px;
    }
}

.SearchField.SearchFieldActive {
    border: #DBDDE0 1px solid;
    padding: 4px 8px;
    border-radius: 8px;
}

.SearchFieldActive .ButtonShow {
    display: none;
}

.SearchFieldDefault .ButtonHide {
    display: none;
}

.SearchClose {
    width: 0;
}

.TableSearch .SearchButton svg {
    color: #5C677D;
    font-size: 18px;
    display: flex;
    cursor: pointer;
}

/* === Search Icon Show hide code == */
.SearchField.SearchFieldDefault .iconArrow {
    display: none;
}

.SearchField.SearchFieldDefault .iconClose {
    display: none;
}

.SearchField.SearchFieldActive .iconArrow {
    display: block;
}

.SearchArrow.iconActive .iconArrow {
    display: none;
}

.SearchArrow.iconHide .iconClose {
    display: none;
}

/* === Search Icon Show hide code == */

.Table_S_B {
    display: flex;
    align-items: center;
}