@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Source Sans 3", sans-serif !important;
  background: #fff !important;
}

.MuiTypography-root {
  font-family: "Source Sans 3", sans-serif !important;
}

.MuiChip-label {
  font-family: "Source Sans 3", sans-serif !important;
}

.MuiInputBase-input {
  font-family: "Source Sans 3", sans-serif !important;
}

.MuiAutocomplete-option {
  font-family: "Source Sans 3", sans-serif !important;
}

.MuiButtonBase-root {
  font-family: "Source Sans 3", sans-serif !important;
}

.MuiMenu-paper .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  font-family: "Source Sans 3", sans-serif !important;
}

input {
  font-family: "Source Sans 3", sans-serif !important;
}

.app-body {
  margin-left: 277px;
  padding: 24px 24px;
}

.Section {
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 20px;
}

.squareBg {
  padding: 4px 8px;
  border-radius: 8px;
  color: #464649;
  background-color: #e8e8e9;
}

.StapperContaier {
  display: flex;
}

.StapperBody {
  padding: 40px;
  width: 24%;
  height: fit-content;
}

.StapperBody .MuiStepper-horizontal {
  display: block !important;
}

.StapperContent {
  margin-left: 20px;
  flex: 1;
}

.MdTitle {
  color: #181c32;
  font-size: 24px;
  font-weight: 600;
  font-family: "Source Sans 3", sans-serif !important;
  margin-bottom: 20px;
}

.StepperButton {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 43%;
  margin: 0 auto;
  margin-left: 38%;
}

.borderGray {
  border: 1px solid #c6c6d0 !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.settings-persone-name {
  display: flex;
  align-items: center;
  gap: 12px;
}

.activeLabel {
  padding: 4px 8px;
  color: #66bd50;
  font-weight: 700;
  border-radius: 100px;
  background-color: #edf7ea;
}

.noDataAvailable {
  font-weight: 400;
  color: #919094;
}

.TimeFieldLabel {
  font-size: 14px !important;
  color: #1b1b1f;
  font-weight: 600 !important;
  display: flex;
  line-height: 1;
  font-family: "Source Sans 3", sans-serif !important;
  display: flex;
  align-items: center;
}

/* ====== Form Css Start ======*/
.FieldLabel {
  font-size: 14px !important;
  color: #1b1b1f;
  margin-bottom: 4px;
  font-weight: 600 !important;
  display: flex;
  height: 26px;
  font-family: "Source Sans 3", sans-serif !important;
  display: flex;
  align-items: center;
}

.MuiFormLabel-root {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 14px !important;
  color: #1b1b1f !important;
  margin-bottom: 4px !important;
  font-weight: 600 !important;
}

.FieldLabel .MuiSvgIcon-root {
  color: #a4a7b0;
  font-size: 22px;
  margin-left: 4px;
}

.FieldLabel span {
  color: #ff0033;
}

.SecFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.HelperText {
  margin: 0;
  padding-top: 4px;
  font-size: 12px;
  color: #676c7b;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.FormBody
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #e4e8ec;
}

.FormBody
  .MuiFormControl-root
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #004fe0;
}

svg.blueColor {
  color: #004fe0 !important;
}

/* ====== Form Css End ======*/

/* ====== Accourding Css Start ======*/
.MuiAccordionSummary-content .MuiTypography-root {
  font-weight: 600;
}

.accFooter {
  display: flex;
  justify-content: end;
  border-top: #e3e3e3 1px solid;
  padding: 10px 16px 0 0;
  margin-top: 24px;
  margin-left: -16px;
  margin-right: -16px;
}

.MuiAccordionDetails-root {
  padding: 8px 16px 10px !important;
}

/* ====== Accourding Css end ======*/

/* ====== Card tab Css end ======*/

.CardTabs .MuiTabs-indicator {
  display: none !important;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root {
  border: #676c7b 1px dashed;
  padding: 20px;
  margin-right: 15px;
  background: #fff;
  border-radius: 4px;
  min-width: 312px;
  font-weight: 600;
  font-size: 14px;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root.Mui-selected {
  border: #009ef7 1px dashed;
  padding: 20px;
  margin-right: 15px;
  background: #f1faff;
  color: #009ef7;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root .CardTabsbutton {
  display: flex;
  align-items: center;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root .CardTabsbutton svg {
  color: #d9dae1;
  margin-right: 15px;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root.Mui-selected .CardTabsbutton svg {
  color: #009ef7;
}

/* ====== Card tab  Css end ======*/

/* ====== Staper Css Start ======*/
.FormStepper {
  padding-bottom: 150px;
}

.StapperContaier .MuiStepLabel-iconContainer {
  border-radius: 6px !important;
  display: inline-block;
  background: #a4a7b0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.StapperContaier .MuiStepLabel-labelContainer {
  padding-left: 8px;
}

.StapperContaier .MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-size: 16px !important;
  color: #676c7b !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
}

.StapperContaier
  .MuiStepLabel-labelContainer
  .MuiStepLabel-label.Mui-completed {
  font-size: 16px !important;
  color: #0b132b !important;
  font-weight: 600 !important;
}

.StapperContaier .MuiStepLabel-iconContainer svg {
  color: #a4a7b0;
}

.StapperContaier .MuiStepLabel-iconContainer .Mui-active {
  color: #004fe0;
  position: relative;
}

.StapperContaier .MuiStepLabel-iconContainer .Mui-active {
  border-radius: 6px !important;
  display: inline-block;
  background: #004fe0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed {
  background: #004fe0;
  position: relative;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed svg {
  color: #fff;
  display: none;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  background: url(../imgs/check_black.svg);
  top: 3px;
  left: 5px;
}

.StapperContaier .MuiStepConnector-horizontal {
  display: none !important;
}

.StapperContaier .MuiStep-root {
  margin-bottom: 38px;
  position: relative;
}

.StapperContaier .MuiStep-root:after {
  content: "";
  border-left: 1px dashed #d7dae3;
  position: absolute;
  height: 38px;
  width: 5px;
  left: 23px;
}

.StapperContaier .MuiStep-root:last-child:after {
  border-left: none;
}

.SpperButtonContainer {
  position: fixed;
  left: 14%;
  bottom: 0;
  background: #fff;
  width: 86%;
  display: flex;
  justify-content: center;
}

.SpperButtonContainer .StepperButton .ButtonContinue {
  background: #004fe0 !important;
  color: #fff !important;
  box-shadow: none !important;
  height: 48px !important;
  border-radius: 8px !important;
  font-weight: 400;
  text-transform: initial !important;
  font-size: 14px;
  padding: 0 20px;
}

.SpperButtonContainer .StepperButton .ButtonContinue svg {
  font-size: 17px;
  margin-left: 8px;
}

/* ====== Staper Css end ======*/

/* ====== Accordion 3 Step start ======*/

.AccrodionThreeStep .MuiPaper-root {
  background: #fff;
  border: #fff 1px solid;
  border-radius: 12px !important;
  margin-bottom: 8px;
  min-height: 96px;
  box-shadow: 0px 0px 6px #00000017 !important;
}

.AccrodionThreeStep .AccrodionInner .MuiPaper-root {
  background: #f4f7fd;
  box-shadow: none !important;
  border: #e4e8ec 1px solid;
  border-radius: 10px !important;
  margin-bottom: 15px;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiPaper-root {
  background: #fff;
  box-shadow: none !important;
  border: #e4e8ec 1px solid;
  border-radius: 10px !important;
  margin-bottom: 15px;
}

.AccrodionThreeStep
  .AccrodionInner
  .AccThreeStep
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(180deg) !important;
  color: #004fe0;
}

.AccrodionThreeStep
  .AccrodionInner
  .AccThreeStep
  .MuiAccordionSummary-expandIconWrapper {
  position: initial;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiAccordionSummary-content {
  margin-left: 0px !important;
}

.SessionListing li {
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  border: #e4e8ec 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.SessionListing li .Sessionleftcol {
  display: flex;
  align-items: center;
}

.SessionListing li .Sessionleftcol .SessionrIcon span {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  background: #e4e8ec;
  color: #676c7b;
  padding: 4px;
  margin-right: 12px;
}

.SessionListing li .Sessionleftcol .SessionListingDetail h4 {
  margin: 0;
  font-size: 16px;
  color: #0b132b;
  font-weight: 600;
}

.SessionListing li .Sessionleftcol .SessionListingDetail p {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.SessionListing li .Sessionleftcol .SessionListingDetail a {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.SessionListing li .Sessionleftcol .SessionListingDetail p svg {
  font-size: 8px;
}

.SessionListing li .SessionListingAction svg {
  color: #676c7b;
}

.SessionListing li .SessionListingAction .lstbtn {
  background: #fff;
  border: #e1e1e7 1px solid;
  border-radius: 10px;
  color: #004fe0;
  font-weight: 600;
  font-size: 14px;
}

.SessionListing li .SessionListingAction .lstbtn svg {
  color: #004fe0;
  font-size: 20px;
  margin-right: 8px;
}

.AccrodionThreeStep .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: 10px;
}

.AccrodionThreeStep .MuiAccordionSummary-content {
  margin-left: 25px !important;
}

.AccrodionThreeStep .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg) !important;
  color: #004fe0;
}

.accTitleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.SessionButton {
  background: #004fe0 !important;
  border-radius: 8px !important;
  height: 40px;
  color: #fff !important;
  padding: 0 15px !important;
  text-transform: initial !important;
}

.SessionButton svg {
  color: #fff !important;
  font-size: 20px;
  margin-right: 6px;
}

.SessionButtonEdit {
  color: #004fe0 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: initial !important;
}

.SesstionStatus {
  margin-right: 10px;
}

.SmallDot {
  font-size: 8px !important;
}

.accTitleSectionIcon {
  display: flex;
  align-items: center;
}

.accTitleSectionIcon h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.accTitleSectionIcon p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #676c7b;
}

.accTitleSectionIcon svg {
  color: #e5e5e5;
  margin-right: 10px;
}

.ScardDetails {
  padding: 0 35px;
}

.ScardDetails p {
  font-size: 14px;
  font-weight: 400;
  color: #676c7b;
  overflow-wrap: anywhere;
}

.Completed {
  color: #0b9444 !important;
}

.MuiPaper-root:before {
  display: none !important;
}

/* ====== Accordion 3 Step End ======*/

.SessionStatus {
  font-size: 14px;
  color: #676c7b;
}

.TextField .MuiOutlinedInput-notchedOutline legend {
  display: none;
}

.MuiFormHelperText-root {
  margin: 4px 0 !important;
  font-size: 12px !important;
  text-transform: initial !important;
}

.priceFiled {
  padding-top: 15px;
  position: relative;
}

.priceFiled .priceICon {
  color: #676c7b;
  font-size: 18px;
  position: absolute;
  top: 60px;
  left: 10px;
}

.priceFiled .MuiInputBase-input {
  text-indent: 20px;
}

.ButtonSaveCont {
  position: fixed;
  top: 7px;
  z-index: 999;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ButtonSave {
  display: flex;
  width: 74.5%;
  justify-content: end;
}

.ButtonSave .ButtonContainer {
  margin: 0 4px;
}

.ItemCount {
  margin: 0 !important;
  color: #676c7b;
  font-size: 14px;
  font-weight: 400;
}

/* ====== Farhan css ======*/

/* ====== user details css ======*/

.UserStatus {
  display: flex;
  background: #fcfcfc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: #e4e8ec 1px solid;
  border-left: #e4e8ec 1px solid;
  border-right: #e4e8ec 1px solid;
  text-transform: capitalize;
}

.UserStatusCol {
  flex: 1;
  border-right: #e4e8ec 1px solid;
  padding: 10px;
  text-align: center;
}

.UserStatusCol:last-child {
  border: none;
}

.UplaodPhotoModal .FieldLabel {
  display: none;
}

.UplaodPhotoModal .FileUplaoder {
  margin-top: 27px;
}

/* ====== user details css  end======*/

.UserDetails li {
  background: #ffffff;
  padding: 24px;
  border: #e4e8ec 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  border-radius: 10px;
}

.UserDetails li .UserDetailsleftcol {
  display: flex;
  align-items: center;
}

.UserDetailsrIcon {
  margin-right: 24px;
  position: relative;
}

.UserDetailsDetail {
  display: flex;
  flex-wrap: wrap;
}

.UserDetailsDetail h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 12px;
  line-height: 32px;
}

.SentChip {
  background: #edf7ea;
  color: #66bd50;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 100px;
  padding: 4px 10px;
  margin-left: 8px;
}

.EditPhoto {
  text-align: center;
}

.UserDetails li .UserDetailsleftcol .UserDetailsrIcon span {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  background: #e4e8ec;
  color: #676c7b;
  padding: 4px;
  margin-right: 12px;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail h4 {
  margin-bottom: 2px;
  font-size: 16px;
  color: #0b132b;
  font-weight: 600;
  margin-top: 0;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail p {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
  word-break: break-all;
  width: 100%;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail a {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail p svg {
  font-size: 8px;
}

.UserDetails li .UserDetailsAction svg {
  color: #676c7b;
}

.UserDetails li .UserDetailsAction .lstbtn {
  background: #fff;
  border: #e1e1e7 1px solid;
  border-radius: 10px;
  color: #004fe0;
  font-weight: 600;
  font-size: 14px;
}

.UserDetails li .UserDetailsAction .lstbtn svg {
  color: #004fe0;
  font-size: 20px;
  margin-right: 8px;
}

.PaymentDetails {
  background-color: #2313b70f;
  padding: 16px;
  border-radius: 12px;
  border: #084f820f 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.OwnerPortalPaymentDetails {
  background-color: #f2f7f30f;
  padding: 16px;
  border-radius: 12px;
  border: #084f820f 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.BigIcon {
  font-size: 12px;
}

/* ====== Landing Page======*/
.Landing {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.Landing a {
  border: #e9e9e9 1px solid;
  padding: 60px;
  border-radius: 28px;
  margin: 15px;
  display: inline-block;
  background: #fff;
  text-decoration: none;
  font-size: 20px;
  text-transform: initial;
}

.AccrodionThreeStep {
  margin-bottom: 20px;
}

.AccrodionThreeStep:last-child {
  margin-bottom: 0px;
}

.UplaodPhotoModal .HelperText {
  max-width: 300px;
  padding-top: 8px;
  font-size: 16px;
}

.divider {
  width: 100%;
  height: 1px;
  display: inline-block;
  border-bottom: 1px solid rgb(238, 237, 237);
  margin-bottom: 32px;
}

.UpdateUseravatar {
  display: flex;
  align-items: center;
}

.UplaodPhotoModal {
  flex: 1;
}

.RedioButton .MuiFormControlLabel-root {
}

.RedioButton .MuiFormGroup-root {
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
}

.addText {
  font-size: 14px;
  color: #004fe0;
  cursor: pointer;
  text-decoration: none;
}

.emptySettionMessage {
  color: #a4a7b0;
  font-size: 14px;
  text-align: center;
}

.checkBox {
  margin-top: 10px;
  width: auto;
  position: relative;
  display: inline-block;
}

.checkBox .MuiFormControlLabel-root {
}

.checkBox .MuiTypography-root {
  font-size: 14px;
  color: #0b132b;
  font-family: "Source Sans 3", sans-serif !important;
}

.StapperBody .MuiTooltip-tooltip {
  left: 0 !important;
  top: 0 !important;
  position: fixed !important;
}

.tabletdvrf {
  display: flex;
  align-items: center;
}

.VerifyChip {
  background: #e1f2e8;
  color: #0b9444;
  font-size: 14px;
  padding: 3px 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  margin-left: 10px;
}

.VerifyChip svg {
  color: #0b9444;
  font-size: 18px;
  margin-right: 4px;
}

.PendingChip {
  background: #ffece0;
  color: #ff6200;
  font-size: 14px;
  padding: 3px 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  margin-left: 10px;
}

.PendingChip svg {
  color: #ff6200;
  font-size: 18px;
  margin-right: 4px;
}

.cellwithchip {
  display: flex;
  align-items: center;
}

.actionDoropdown .MuiButtonBase-root {
  border: #e4e8ec 1px solid;
  height: 48px;
  padding: 0 15px;
  text-transform: initial !important;
  font-weight: 600;
  border-radius: 8px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: #e4e8ec 1px solid !important;
}

.MarkCompleted {
  display: flex;
  align-items: center;
  margin-top: 15px;
  border-top: #e9e9e9 1px solid;
  padding-top: 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

.MarkCompleted span {
  color: #004fe0;
  font-size: 14px;
  font-weight: 600;
}

.MarkCompleted svg {
  display: flex;
  color: #e4e8ec;
  margin-right: 8px;
  font-size: 30px;
}

.LargeText .TextField .MuiInputBase-root {
  width: 100%;
  height: 70px;
  font-size: 34px;
  font-weight: 700;
}

.LargeText .FieldLabel {
  font-size: 20px;
  color: #0b132b;
  font-weight: 500;
  margin-bottom: 10px;
}

.AvailaleContainer {
  position: relative;
}

.AvailableButton {
  color: #004fe0;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 58px;
  cursor: pointer;
  font-weight: 600;
}

.AvailVerify {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
}

.AvailVerify svg {
  font-size: 19px;
  margin-right: 4px;
}

.AvailVerify.Yes {
  color: #0b9444;
}

.AvailVerify.No {
  color: #ba1a1a;
}

.LoadingBar {
  position: absolute;
  right: 20px;
  top: -10px;
}

.AvailableButton.IfLoading {
  color: #676c7b;
}

.ActionButton .MuiButtonBase-root {
  border: #e4e8ec 1px solid;
  height: 36px;
  border-radius: 8px;
  padding: 0 20px;
  text-transform: uppercase !important;
  color: #004fe0;
  font-weight: 600;
}

.BalanceCard .BalanceCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BalanceCardHeader h1 {
  font-size: 20px;
  color: #0b132b;
  margin: 0;
}

.BalanceCard .BalanceCardBody h2 {
  font-size: 48px;
  color: #0b132b;
  margin: 0;
  font-weight: 600;
}

.BalanceCard .BalanceCardBody p {
  font-size: 16px;
  color: #676c7b;
  margin: 0;
}

.RecentAnnouncementCard {
  background: #fff;
  box-shadow: 0px 0px 6px #00000017;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.RecentAnnouncementCard h1 {
  border-radius: 10px;
  font-size: 20px;
  color: #0b132b;
  margin: 0;
}

.RecentAnnouncementCard h5 {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #676c7b;
}

.RecentAnnouncementCard p {
  color: #676c7b;
  font-size: 16px;
}

.RecentAnnouncementimg {
  display: flex;
}

.RecentAnnouncementimg .box {
  background: #ffece0;
  padding: 17px;
  display: flex;
  border-radius: 10px;
  margin-right: 24px;
  color: #ff6200;
}

.RecentAnnouncementimg {
  display: flex;
  align-items: center;
}

.verifiedIcon {
  margin-left: 5px;
}

.PassworedDots svg {
  font-size: 10px;
}

/* ==== Side Bar ==== */

.SidebarMenu {
  position: absolute;
  z-index: 105;
  bottom: 0;
  left: 0;
  max-width: 280px;
  width: 100%;
  top: 66px;
  background: #fff;
  box-shadow: 0px 1px 4px #00000017;
  flex: 1 1;
}

.SidebarMenu .MuiList-root {
  position: fixed;
  height: 100%;
  background: #fff;
  width: 270px;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root {
  padding: 0 !important;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root a {
  padding: 14px 28px;
  display: flex !important;
  align-items: center;
  width: 100%;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root a {
  display: inline-block;
  text-decoration: none;
  color: #0b132b;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root .MuiSvgIcon-root {
  color: #0b132b;
}

.SidebarMenu .divider {
  width: 278px;
  height: 1px;
  display: inline-block;
  border-bottom: 1px solid rgb(238, 237, 237);
}

.BackButton svg {
  font-size: 18px;
}

/* ==== Side Bar End ==== */

.ResponsiveMenu {
  display: none;
}

.OrderTotalSection {
  background: #ebf1fd;
  border-radius: 10px;
  padding: 16px;
  text-align: center;
}

.OrderTotalSection p {
  color: #676c7b;
  font-size: 12px;
  margin: 0;
}

.OrderTotalSection h2 {
  color: #0b132b;
  font-size: 24px;
  margin: 0;
}

.OrderTotalSection h3 {
  color: #676c7b;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.OrderTotalSection h1 {
  color: #0b132b;
  font-size: 48px;
  margin: 0;
}

.Address span {
  margin-top: 15px;
  display: block;
}

/* .TableContainer .MuiTableRow-root.MuiTableRow-hover {
  background-color: transparent !important;
} */

.Stickyheader {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.Stickyheader .HeaderContainer {
  position: sticky !important;
}

.FontMedium {
  font-weight: 500 !important;
}

.TableInfo {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.TableLabel {
  color: #46464a !important;
  font-size: 14px !important;
  font-weight: 400;
}

.TableLabel h4 {
  color: #46464a !important;
  font-size: 16px !important;
  margin: 0;
  font-weight: 600;
}

.TableInfo a {
  color: #004fe0 !important;
}

/* .oddEvenTable .MuiTableCell-root {
  border: none !important;
} */

.ModalForm .MuiOutlinedInput-notchedOutline {
  top: 0px !important;
  height: 48px;
}

.ModalForm .TextField .MuiInputBase-root .MuiInputBase-input {
  position: relative;
  top: 0px;
  height: 8px;
}

.Stickyheader .HeaderContainer {
  position: sticky !important;
}

.formContainer {
  margin-top: 24px;
}

.PeronalInfocard {
  display: flex;
  align-items: center;
}

.PeronalInfocardDetail {
  display: flex;
}

.PeronalInfocard .PeronalInfocardDetail h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.PeronalInfocard .PeronalInfocardDetail p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #676c7b;
}

.PeronalInfocard .UserIcon {
  margin-right: 15px;
}

.PeronalInfocard .PeronalInfocardDetail .MuiChip-root {
  margin-left: 10px;
  border-radius: 4px;
  height: 30px;
}

.FeeChip .MuiChip-root.MuiChip-filled {
  background-color: #e1f2e8;
  color: #0b9444;
  border-radius: 4px;
  margin-right: 8px;
}

.FeeChip a {
  color: #004fe0;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}

.infoBox {
  color: #0b132b;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}

.infoBox a {
  color: #004fe0;
  font-size: 14px;
  text-decoration: none;
  font-family: "Poppins", sans-serif !important;
}

.Readmore {
  color: #004fe0 !important;
  text-decoration: none;
  cursor: pointer;
}

.PricingCell {
  display: none;
}

.MenuListing {
  z-index: 99;
}

.DeleteTrack {
  background: #fff;
  padding: 24px;
  box-shadow: 0px 0px 6px #00000017;
  border-radius: 12px;
}

.lrTable .Tablechip {
  min-width: 30px;
  min-height: 33px;
  display: flex;
  align-items: center;
}

.lrTable .chipContainer {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.MarkCheck {
  padding-top: 10px;
}

.StatusMessage svg {
  color: #ba1a1a;
  margin-right: 16px;
}

.DeleteTrack h3 {
  color: #0b132b;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.DeleteTrack p {
  color: #676c7b;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.DeleteTrack .ButtonContainer {
  margin-top: 16px;
}

.emptyIcon {
  display: flex;
  justify-content: space-between;
}

.emptyIcon svg {
  position: absolute;
  right: 0px;
  font-size: 22px;
  color: #ba1a1a;
}

.TableContainer .MuiTable-root .MuiTableSortLabel-root {
  cursor: auto !important;
}

.tableLableTooltip {
  display: flex;
  color: #676c7b;
}

.tableLableTooltip svg {
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root
  svg {
  margin-right: 8px;
}

.TableColDescription .MuiTableCell-root {
  vertical-align: baseline;
}

.TotalMonth {
  position: relative;
}

.TotalMonth .MonthLabel {
  position: absolute;
  right: 37px;
  top: 26px;
  font-size: 16px;
  color: #a4a7b0;
  font-weight: 400;
}

.TotalMonth input[type="number"]::-webkit-inner-spin-button,
.TotalMonth input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

/* ================================ PaymentMethodBox button css start ====================================== */
.PaymentMethodBox .MuiTabs-indicator {
  display: none;
}

.PaymentMethodBox .PaymentMethodBoxTabBody .MuiBox-root {
  padding: 0;
}

.PaymentMethodBox .MuiTab-root {
  border: #e4e8ec 1px solid;
  border-radius: 8px;
  margin-right: 16px;
  flex: 1;
  width: 100%;
  padding: 16px;
  max-width: initial !important;
}

.PaymentMethodBox .RedioContents {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.PaymentMethodBox .RedioContents .RedioLebel {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.PaymentMethodBox .RedioContents .RedioLebel h3 {
  margin: 0;
  font-size: 14px;
  text-transform: initial;
}

.PaymentMethodBox .MuiTab-root.Mui-selected h3 {
  color: #004fe0;
}

.PaymentMethodBox .RedioContents .RedioLebel p {
  margin: 0;
  font-size: 14px;
  text-transform: initial;
  font-size: 1rem;
  font-weight: 900;
  width: 100%;
  text-align: left;
}

.PaymentMethodBox .MuiTab-root.Mui-selected {
  border: #004fe0 1px solid;
  border-radius: 8px;
  background: #f8f8f9;
}

.PaymentMethodBox .UnSelectedRedio {
  color: #ffffff;
  border-radius: 100px;
  /* border: #A4A7B0 2px solid; */
  font-size: 12px;
}

.PaymentMethodBox .MuiTab-root.Mui-selected .UnSelectedRedio {
  display: none;
}

.PaymentMethodBox .MuiTab-root .SelectedRedio {
  display: none;
}

.PaymentMethodBox .MuiTab-root.Mui-selected .SelectedRedio {
  color: #004fe0;
  display: block;
  padding: 0;
  /* border: #004FE0 1px solid; */
  border-radius: 100px;
  font-size: 18px;
}

.PaymentMethodBox .RedioIcon {
  position: relative;
  top: -11px;
  right: -11px;
}

.PaymentMethodBox .MuiTabs-root {
  display: flex;
  overflow: visible !important;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
}

.PaymentMethodBox .MuiTabs-scroller {
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow: visible !important;
}

.PaymentMethodBox .MuiTab-root:last-child {
  margin-right: 0 !important;
}

.PaymentMethodBox .MuiTab-wrapper {
  flex-direction: initial !important;
  justify-content: left !important;
}

.PaymentMethodBoxTabBody {
  padding-top: 32px;
}

.Branding {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 480px) {
  .PaymentMethodBox .MuiTab-root {
    max-width: 114px !important;
    padding-left: 7px;
  }

  .PaymentMethodBox .RedioContents {
    flex-direction: row-reverse;
  }
}

/* ================================ PaymentMethodBoxTabBody button css end ======================================== */

/* =============================== cash payment date field start ==================================== */

.PDrawerLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pendingChip {
  padding: 5px;
  border-radius: 8px;
  color: #ffaa00;
  background-color: #fff5e0;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.MuiFormControl-root {
  width: 100%;
}

.Datefield .MuiInputLabel-outlined {
  top: -3px;
}

@media (max-width: 768px) {
  .Datefield {
    width: 100%;
  }
}

.CrLst {
  padding-bottom: 15px;
}

.DateTime .MuiInputBase-root {
  position: relative;
}

.DateTime .MuiInputBase-root::after {
  content: "";
  position: absolute;
  background-image: url(../imgs/dateIcon.svg);
  width: 40px;
  height: 40px;
  right: -5px;
  top: 12px;
  background-repeat: no-repeat;
}

/* =============================== cash payment date field end ==================================== */

/* == SUMMARY == */

.SummarySection {
  margin-bottom: 32px;
}

.SummaryCar h1 {
  font-size: 20px;
  color: #0b132b;
  font-weight: 600;
  margin-top: 0;
}

.SummaryCar .SummaryTable {
  background: #fff;
  border-radius: 8px;
  border: #e4e8ec 1px solid;
}

.SummaryCar .SummaryTable .SummaryTableRwo {
  display: flex;
  justify-content: space-between;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell {
  padding: 10px;
  font-size: 14px;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell.LightText {
  font-weight: 500;
  color: #676c7b;
}

.SummaryCar .SummaryTable .SummaryTableRwo .SummaryTableCell.BoldText {
  font-weight: 600;
  color: #0b132b;
}

.SummaryCar .SummaryTable .SummaryTableRwo.DarkRwo {
  background: #f8f8f9;
}

.TableImgCell {
  display: flex;
  align-items: center;
}

.TableImgCell img {
  width: 40px;
}

.TableImgCell p {
  margin: 0;
  color: #0b132b;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.SumamryTitle {
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
  margin-bottom: 4px;
}

.PMI {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 4px;
}

.PMI img {
  display: inline-block;
  width: 18px;
}

.CrLst {
  padding-bottom: 15px;
}

.PupColor {
  background: #6d1fd4;
}

.BlueColor {
  background: #004fe0;
}

/* == SUMMARY == */

.buttonChip {
  margin-left: 5px;
  padding: 4px 6px;
  width: fit-content;
  font-weight: 600;
  font-size: 10px;
  display: flex;
}

.MuiSnackbar-root .MuiAlert-message {
  color: #fff !important;
}

.PageTitle {
  color: #000;
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 28px;
}

/* ==== RESPONSIVE ==== */

.PrimaryTeacher {
  position: relative;
  border: #dedede 1px solid;
  border-radius: 8px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.PrimaryTeacherinfo {
  border-radius: 8px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 10px;
}

.PrimaryTeacher .FieldTextBox span {
  font-weight: 600;
}

.PrimaryTeacher .FieldTextBox {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.PrimaryTeacher .FieldTextBox svg {
  color: #d9d9d9;
  font-size: 14px;
  margin: 0 8px;
}

.PrimaryTeacher .iconClose {
  color: #919094;
  font-size: 26px;
  position: absolute;
  right: 5px;
  top: 3px;
}

.PrimaryTeacher input {
  height: 0;
  opacity: 0;
  width: 0;
}

@media (max-width: 1500px) {
  .app-body {
    width: 79%;
    height: 400px;
    margin-left: 21%;
    padding: 20px;
  }

  .textDate {
    min-width: 150px;
  }

  .CellCont {
    min-width: 150px;
  }
}

@media (max-width: 1300px) {
  .DesktopView {
    display: none;
  }

  .ResponsiveMenu {
    display: block;
  }

  .app-body {
    width: auto;
    height: 400px;
    margin-left: 0;
    padding: 20px;
  }

  .Header .Branding img {
    height: 25px;
  }

  .SidebarMenu {
    width: 280px;
    max-width: initial;
  }

  .Header {
    height: 56px;
    justify-content: space-between;
    align-items: center;
  }

  .MuiTableContainer-root {
    width: 800px;
    overflow-x: auto !important;
  }

  .SideBarDrawer .MuiDrawer-paperAnchorLeft {
    width: 250px;
  }

  .SidebarMenu {
    width: 250px;
    max-width: initial;
  }

  .SideBarDrawer .MuiDrawer-paper {
    overflow-y: initial;
  }
}

@media (max-width: 768px) {
  .UserDetails li {
    display: block;
    margin-top: 20px;
    border-radius: 10px;
  }

  .UserDetails li .ButtonContainer .MuiButtonBase-root {
    width: 100%;
    margin-top: 15px;
  }

  .accTitleSection .MuiTypography-root {
    width: fit-content;
  }

  .UpdateUseravatar {
    display: block;
  }

  .EditPhoto {
    text-align: center;
    display: block;
  }

  .EditPhoto .FieldLabel {
    display: inline-block;
  }

  .EditPhotoAvatar {
    display: flex;
    justify-content: center;
  }

  .ScardDetails {
    padding: 0 0;
  }

  .accTitleSection {
    display: block;
  }

  .accTitleSectionIcon {
    display: block;
  }

  .SesstionStatus {
    margin-top: 10px;
    position: absolute;
    top: 10px;
    right: 50px;
  }

  .accTitleSectionIcon p {
    margin-right: 5px;
  }

  .ScardDetails p {
    margin-top: 0;
  }

  .AccrodionThreeStep
    .AccrodionInner
    .AccThreeStep
    .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    top: 20px;
    right: 19px;
    left: initial !important;
  }

  .SessionListing li .Sessionleftcol {
    display: block;
  }

  .SessionListing li {
    display: block;
  }

  .SessionListing li .SessionListingAction .lstbtn {
    width: 100%;
    margin-top: 10px;
  }

  .emptyIcon svg {
    position: relative;
    right: -6px;
  }
}

.error-msg {
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #d32f2f;
  line-height: 1.66;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.infoIcon {
  display: flex;
  align-items: center;
}

.infoIcon svg {
  margin-left: 8px;
}

@media (max-width: 520px) {
  .accTitleSectionIcon svg {
    color: transparent;
    display: block;
  }
}

.ABsentSwitchCol {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.ABsentSwitchCol .AbsLeft h1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  color: #1b1b1f;
}

.ABsentSwitchCol .AbsLeft p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: #46464a;
}

.DrawerContainer .DrawerTabsContainer .MuiBox-root {
  padding: 0 !important;
}

.LaunchingCard {
  text-align: center;
  padding-top: 156px;
  padding-left: 25px;
  padding-right: 25px;
}

.LaunchingCard .PortalLogo img {
  width: 262px;
}

@media (max-width: 768px) {
  .LaunchingCard .PortalLogo img {
    width: 100%;
  }
}

.LaunchingCard h1 {
  text-align: center;
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 57px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  color: #1b1b1f;
  margin: 0;
  margin-top: 24px;
}

.LaunchingCard p {
  text-align: center;
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  color: #1b1b1f;
  margin: 0;
  margin-top: 24px;
}

.CourseSettings .CourseSettingsHeader h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #1b1b1f;
  margin: 0;
}

.CourseSettings .CourseSettingsHeader p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #46464a;
  margin-top: 8px;
}

/* ==== Fragand Drop ===== */

.DragableContainer {
  border: #edf7ea 1px solid;
  min-height: 56px;
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
}

.DragMoveIcon {
  background: #eff0ff;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  cursor: move;
}

.DNDLeftCol {
  display: flex;
  align-items: center;
}

.DNDLeftInfo {
  margin-left: 10px;
  display: flex;
}

.DNDLeftInfo h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  color: #1b1b1f;
  margin-right: 12px;
}

.IconCell {
  display: flex;
}

.IconCell img {
  margin-left: 4px;
  width: 18px;
}

.RoleDetailsInner {
  padding: 22px;
}

.RoleDetails .MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0 !important;
  margin: 0 !important;
}

.RoleDetails .SwitchAccordion .MuiAccordion-root.MuiAccordion-rounded {
  background: #fff;
  box-shadow: none;
  margin-bottom: 0px;
}

.PrmSection {
  border-bottom: #e6eaf0 1px solid;
  padding: 14px 24px;
}

.StudentDetailsContainer .MuiBox-root {
  padding: 0 !important;
}

.RoleDetails .PrmSection .PrmSectionIn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.RoleDetails .PrmSection .PrmSectionIn h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.RoleDetails .PrmSection .PrmSectionIn .MuiOutlinedInput-notchedOutline {
  display: none;
}

.PrmSectionIn .MuiInputBase-root em {
  font-style: normal !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: "Source Sans 3", sans-serif !important;
  color: #5e5e62;
}

.PrmSectionIn .MuiSelect-select {
  padding: 0 24px !important;
  height: 24px;
  padding-left: 12px !important;
}

.PrmSectionIn .MuiSelect-select:hover {
  background: #f3f2ff;
}

.PrmSectionIn .MuiInputBase-root .MuiSvgIcon-root {
  right: 0 !important;
}

.RoleDetails .MuiAccordionDetails-root {
  padding: 0 !important;
}

.RoleDetails .SwitchAccordion .MuiAccordion-root.MuiAccordion-rounded {
  margin-top: 8px;
  margin-left: -24px;
  margin-right: -24px;
}

.RoleDetails .MuiAccordionSummary-content {
  padding: 0 24px !important;
}

.RoleDetails
  .SwitchAccordion
  .MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
  margin-left: -24px;
  margin-right: -24px;
  border-radius: 0 !important;
}

.PrmSectionIn p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #77767a;
  margin: 0;
}

.RoleDetails .MuiAccordionDetails-root .PrmSectionIn h1 {
  font-weight: 400 !important;
}

.TabInnerCot {
  padding: 24px;
}

.UserRoll .TableHeader {
  padding: 22px;
  padding-bottom: 0;
}

.UserRoll .TableHeader .TableTitle {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
}

.AccBox {
  padding: 0 24px !important;
}

/* .oddEvenTable {
  overflow: hidden !important;
} */

.BoxTable {
  border: #e4e2e6 1px solid;
  border-radius: 12px;
  /* overflow: hidden; */
}

.AttendanceSettings {
  border: #e4e2e6 1px solid;
  border-radius: 12px;
  overflow: hidden;
}

.AttendanceSettings .TableInnerHeader {
  padding: 16px 24px !important;
}

.AttendanceSettings .SwitchButtonIOS {
  display: flex;
  align-items: center;
  justify-content: left;
}

.ExportLabel {
  color: #5e5e62 !important;
}

.QrDetailsContainer {
  margin: 0 !important;
  padding: 16px 0;
  border-bottom: 1px solid #e8eaed;
}

.QrDetailsContainer:last-child {
  border-bottom: 0px;
}
