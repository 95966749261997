.ButtonContainer {
    position: relative;
}

.ButtonContainer .MuiButtonBase-root {
    border-radius: 8px !important;
    padding: 0 10px;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Source Sans 3', sans-serif !important;
    font-size: 14px;
    padding-right: 12px !important;
    line-height: 20px;
}

.ButtonContainer .Mui-disabled {
    background: #E1E2E5 !important;
    color: #A7AAB3 !important;
}

.ButtonContainer .MuiButtonBase-root svg {
    margin-right: 10px;
}

.ButtonIconAfter {
    display: flex;
}

.ButtonIconAfter svg {
    margin: 0 !important;
}

.filterCount {
    margin-left: 5px;
    padding: 0 4px;
    color: #fff;
    font-weight: 600;
    background-color: #BA1A1A;
    border-radius: 16px;
}

.ButtonLoader {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;
}

.ButtonLoader .MuiCircularProgress-root .MuiCircularProgress-svg {
    margin: 0 !important;
    padding: 0 !important;
}